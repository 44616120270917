div.certification-upload-dropzone {
    width: 250px;
    height: 70px;

    input {
        width: 100%;
        height: 100%;
        border: 1px solid orange;
        background: blue;
    }
}

.upload-btn-text {
    color: rgba(128, 128, 128, 0.579);
    font-weight: 700;
    font-stretch: 0.5rem;
}
