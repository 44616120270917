@import '../../../../../scss/mixins.scss';

.onboarding-start-header-container {
  ion-header {
    background: transparent;

    ion-toolbar {
      --background: transparent;

      ion-icon {
        color: var(--ion-color-light);
      }
    }
  }
}
.content-container-start {
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  flex-direction: column;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  h2 {
    text-align: center;
    color: var(--ion-color-light);
    text-transform: uppercase;
  }

  ion-content.onboarding-start-content {
    --background: transparent;
    border-top-right-radius: 0 !important;
    &::-webkit-scrollbar {
      display: none;
    }
    &::part(scroll) {
      overflow-y: hidden;
    }
    @include for-desktop-up {
      border-top-right-radius: 0px !important;
    }
    &::part(content) {
      height: 100vh;
    }

    div {
      width: 100%;
      height: 90%;
      padding: 10%;
      background-color: var(--ion-color-light);
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column;

      @include for-desktop-up {
        border-radius: 0px;
      }

      span {
        text-align: center;
        padding: 1rem;
        color: rgb(72, 72, 72);
        font-weight: 600;
        font-size: 1.5rem;
      }

      h3 {
        text-align: center;
        color: rgba(0, 0, 0, 0.787);
        font-weight: 900;
      }
    }
  }

  ion-footer {
    padding-bottom: 5rem;
  }
}
