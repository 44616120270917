input[type='color'].androidColourInput {
  border: none;
  outline: none;
  background: none;
  height: 40px;
  cursor: pointer;
}

input[type='color'].iosColourInput {
  width: 30px;
  border-radius: 0;
  height: 30px;
  border-color: transparent;
  outline-color: transparent;
  cursor: pointer;
  background: none;
}
