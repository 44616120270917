.project-card {
    background: #330756;
    color: white;
}

div.project-list-page-check-information-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 500;
    ion-grid.periodically-reload-spinner-container {
        ion-row {
            max-width: 1000px;
        }
    }
    .rowContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;
    }
}
