@import '../../scss/mixins.scss';

ion-modal.economy-monitoring-add-modal {
    --overflow: hidden;
    --border-radius: 30px;
    --border-width: 0;
    --border-style: none;
    --border-color: transparent;
    --box-shadow: none;
    --height: 600px !important;

    &::part(content) {
        max-width: 500px;
    }
    ion-toolbar {
        height: 150px;
    }
    ion-content {
        max-width: 500px;
    }

    @include for-phone-only {
        --width: 100%;
        --height: 500px !important;
    }
}

.economy-monitoring-rows-swiper {
    padding-right: 16px;

    ion-label {
        text-align: right;
        --padding-start: 0;
        color: var(--ion-color-medium);
        margin: 0;

        p {
            text-align: right;
            font-size: 0.7rem;
            margin: 0;
        }
    }
}
