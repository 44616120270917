div.act-code-tile-container-row {
  flex-direction: row;

  max-width: 450px;

  .name-box {
    p {
      span {
        margin: 0 4px;
      }

      padding-top: 4px;
      font-size: 16px !important;
    }
  }
}

div.act-code-tile-container-col {
  flex-direction: column;
}

div.act-code-tile-container-col,
div.act-code-tile-container-row {
  border-left: 2px solid var(--ion-color-secondary);
  height: 60px;
  background-color: var(--ion-color-light);
  box-shadow: var(--ion-color-primary-box-shadow);

  div.act-code {
    p {
      text-align: left;
      color: var(--ion-color-medium);
      // font-weight: 600;
      margin: 0;
      padding-left: 5px;
    }
  }

  .name-box {
    padding: 5px;
    cursor: pointer;

    p {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.no-pointer {
      cursor: default !important;
    }
  }
}
