@import '../../../../../scss/mixins.scss';

ion-toolbar.personaloverview-toolbar {
  --background: transparent;

  ion-button {
    margin-top: 30px;

    &::part(native) {
      background-color: var(--ion-color-light);
      padding: 0;
    }
  }

  ion-col.onboarding-avatar-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;

    ion-avatar {
      width: 200px;
      height: 200px;
      display: flex;
      justify-content: center;
      margin: 30px;

      img {
        object-fit: cover;
      }
    }

    p {
      position: absolute;
      bottom: 0;
      width: 300px;
      color: var(--ion-color-light);
      font-weight: 900;
      font-size: 1.5rem;
      background-color: #222854;
      box-shadow: var(--box-shadow);
      border-radius: 3px;
    }
  }

  ion-buttons {
    ion-button {
      width: 50px;
      height: 50px;
      ion-icon {
      }
    }
  }
}

ion-content.personaloverview-content-container {
  border-top-right-radius: 0px;
  --offset-bottom: auto !important;
  --overflow: hidden;
  background-color: var(--ion-color-light);
  border-radius: 15px !important;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  ion-list.project-checkin-container {
    padding-top: 24px;
    ion-label {
      font-size: 1.2rem;
      font-weight: 600;
    }

    ion-item {
      ion-buttons {
        height: 8rem;
        width: 100%;
        display: flex;
        gap: 15%;
        justify-content: space-evenly;

        div {
          text-align: center;

          ion-button {
            color: black !important;
            --padding-start: 0;
            --padding-end: 0;
            width: 50px;
            height: 50px;
            padding: 0 !important;
            --box-shadow: var(--ion-color-primary-box-shadow);

            ion-icon {
              color: var(--ion-color-dark);
            }
          }

          ion-label {
            font-size: 1.2rem;
            margin: 0 !important;
          }
        }
      }
    }
  }
}

ion-content.access-info-modal-content {
  border-radius: 20px;
  height: 100%;
  border-top-right-radius: 20px !important;

  ion-grid {
    ul {
      li {
        a {
          color: var(--ion-color-secondary);
        }
      }
    }
  }
}
ion-list.no-access-list-container {
  ion-list-header {
    ion-text {
      h1 {
        font-size: 4rem;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.742);
      }
      h3 {
        font-size: 2.5rem;
        font-weight: 600;
      }
    }
  }
  ion-item {
    padding-left: 16px;
    ion-icon {
      font-size: 8px;
    }
    ion-label {
      margin: 8px 16px;
      font-size: 2rem !important;
      font-weight: 600;

      a {
        color: var(--ion-color-primary-shade);
      }
    }
  }
}
