.project-information-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .project-information-inner-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 6px;
    }
}
