.precalculation-form-container {
  --background: var(--ion-color-light);
  border-top-right-radius: 0 !important;

  ion-toolbar {
    --background: var(--ion-color-light);

    ion-title {
      text-transform: capitalize;

      ion-button {
        --background-activated: transparent;
        --background-hover: black;
        --background-focused: black;
        --background-activated-opacity: 0;
        --background-focused-opacity: 0.24;
        --background-hover-opacity: 0.08;
        --box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
          0 1px 5px 0 rgba(0, 0, 0, 0.12);
        --border-radius: 50%;
        --background: none;
        width: 30px;
        height: 30px;
        --padding-start: 0;
        --padding-end: 0;

        ion-icon {
          color: rgb(112, 140, 202);
        }
      }
    }
  }

  .precalculation-form {
    background: transparent;
    gap: 10px;

    .precalc-summary-btn {
      --border-radius: 2px;
      --padding-top: 16px;
      --padding-bottom: 16px;
      --padding-start: 1.1em;
      --padding-end: 1.1em;
      --transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), background-color 15ms linear, color 15ms linear;
      --background: var(--ion-color-tertiary);
      --color: var(--ion-color-light);
      height: 70px;
      width: 100%;
      font-size: 1.8rem;
      font-weight: 500;
      letter-spacing: 0.06em;

      &::part(native) {
        padding: 2rem !important;
      }
    }

    .precalc-input-border {
      height: 1px;
      width: 100px;
      background-color: black;
    }

    .precalc-block-input {
      width: 100%;
    }

    ion-toolbar.precalc-toolbar-bottom {
      position: absolute;
      bottom: 32px;
      text-align: center;
      left: 0;

      ion-button {
        font-size: 1.6rem;
        color: gray;
        text-transform: capitalize;
      }

      ion-button.precalc-save-btn {
        --background: #edac53;
        --border-radius: 5px;
        --color: var(--ion-color-light);
        text-transform: capitalize;
      }
    }
  }
}

.confirm-cost {
  ion-text {
    width: 20%;
    p {
      font-size: 16px;
      letter-spacing: 2px;
      color: dimgray;
    }
  }

  ion-text {
    span {
      font-size: 2524px;
    }
  }

  ion-checkbox {
    width: 32px;
    height: 32px;
  }
}
