@import '../../../scss/mixins.scss';

$positionVertical: 30px;
$positionHorizontal: 30px;

.sectionSelectButton {
  position: absolute;
  bottom: 10%;
  &Left {
    left: $positionHorizontal;
  }
  &Right {
    right: $positionHorizontal;
  }
  @include for-phone-only {
    bottom: 5rem;
  }
}

.mapContainer {
  width: 100vw;
}

.levelController {
  position: absolute;
  bottom: $positionVertical;
  left: $positionHorizontal;
  height: 20%;
  width: 40px;
  background: var(--ion-color-light);
  overflow: scroll;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.resetButton {
  position: absolute;
  right: $positionHorizontal;
  bottom: $positionVertical;
}
