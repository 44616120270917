ion-modal.add-not-posted-cost-modal {
  --max-width: 300px;
  --max-height: 480px;
  --border-radius: 16px;

  ion-content {
    --offset-bottom: auto !important;
    --overflow: hidden;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

ion-modal.add-change-or-deviation-modal {
  --max-width: 300px;
  --max-height: 530px;
  --border-radius: 16px;

  ion-content {
    --offset-bottom: auto !important;
    --overflow: hidden;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.tile {
  border-radius: 4px;
  min-height: 60px;
  width: 100%;

  .container {
    height: 60px;
    margin-bottom: 1rem;
    box-shadow: #00000033 0px 2px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .title {
    font-size: 12px;
    display: inline-grid;
    border-radius: 4px;
    text-align: center;
    text-overflow: ellipsis;
    width: 95%;
  }

  .economyMonitoring,
  .isChanges,
  .isDeviation,
  .postedCosts {
    .has-not-posted-costs {
      background-color: #f1ff7b;
    }

    .has-changes {
      background-color: #bde3ff;
    }

    .has-deviations {
      background-color: #ffe2bb;
    }

    .has-posted-costs {
      background-color: rgb(255 228 250);
    }
  }

  .value {
    font-size: 10px;
    max-width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
