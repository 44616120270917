ion-grid.createChangeTopSection {
  ion-col.headerWrapper {
    border-left: 5px solid var(--ion-color-secondary);
  }
  ion-label.relatedSelectLabel {
    font-size: 16px;
    font-weight: 800;
    color: var(--ion-color-dark);
    opacity: 0.8;
  }
}

ion-grid.createdChangeListContainer {
  min-height: 30vh;
}

div.stepButtonsWrapper {
  width: 100vw;
}

// ROWS COMPONENT:
ion-row.createdChangeRowsWrapper {
  ion-col.columnsWithInput {
    box-shadow: var(--ion-color-primary-box-shadow);
    height: 50px;
    margin-bottom: 1rem;
    padding-right: 5px;
    padding-left: 5px;
    ion-input {
      height: 50%;
      border: 1px solid var(--ion-color-medium);
      border-radius: 16px;
      width: 100%;
    }
  }
  ion-col.columnsNoInput {
    margin-bottom: 1rem;
  }
}

ion-modal.changeCommentModal {
  --max-width: 300px;
  --max-height: 430px;
  --border-radius: 16px;

  ion-content {
    --offset-bottom: auto !important;
    --overflow: hidden;
    overflow: auto;
  }
}
// CHANGE PAGE:
div.changePageContainer {
  width: 100%;
  max-width: var(--ion-desktop-max-width);

  ion-row.changePageHeaderContainer {
    border-radius: 8px;
    margin-bottom: 16px;

    ion-col.nameContainer {
      padding-left: 5px;
      border-radius: 8px;
    }
  }
  ion-grid.changeRowsContainer {
    ion-col {
      height: 50px;
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;

      ion-label {
        font-weight: 800;
      }

      p {
        font-size: 14px;
      }
    }
    ion-row.commentContainer {
      ion-col {
        min-height: 150px;

        span {
          background-color: var(--ion-color-light);
          min-height: 100px;
          font-size: 16px;
        }
      }
    }
  }
}
