.support-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 34px;
    border-bottom: 1px solid orange;
    width: 40%;
}

.support-container {
    display: flex;
    gap: 8px;
    flex-direction: column;
    max-width: 600px !important;

    ion-item {
        --inner-padding-end: 4px;

        a {
            text-decoration: none;

            ion-label {
                h3 {
                    color: var(--ion-color-medium);
                }
                p {
                    color: var(--ion-color-dark);
                    font-size: 15px;
                }
            }
        }
    }
}
