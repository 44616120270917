@import '../../../../../scss/mixins.scss';

.id06-modal-container,
.id06-add-modal-container,
.id06-information-modal-container {
  --min-height: 400px;
  --max-height: 60%;

  ion-header {
    margin-bottom: 3rem;

    ion-button {
      ion-icon {
        color: gray !important;
        font-size: 6rem !important;
        margin: 0;
      }
    }

    ion-title {
      color: rgba(0, 0, 0, 0.835);
      font-weight: 900;
      font-size: 4rem;
      padding-left: 4rem;
      text-decoration: underline;
    }
  }

  @include for-desktop-up {
    --width: 30%;
  }
}

.id06-modal-container {
  --background: orange;
  display: flex !important;
  justify-content: center !important;
  --box-shadow: none;
  --width: 95%;
  --max-height: 80%;
  --backdrop-opacity: 0.8;
}

.id06-add-modal-container {
  display: flex !important;
  justify-content: center !important;
  --backdrop-opacity: 0.38 !important;
  --border-radius: 20px;

  &::part(backdrop) {
    background-color: black;
  }

  @include for-desktop-up {
    &::part(content) {
      max-height: 600px;
      max-width: 500px;
    }
  }

  ion-toolbar {
    ion-title {
      font-size: 3rem;
      text-align: left;
    }
  }

  ion-content {
    --offset-bottom: auto !important;
    --overflow: hidden;
    background-color: white;
    overflow: auto;

    ion-button.qr-scan-btn {
      width: 80px;
      height: 80px;
      --padding-start: 0;
      --padding-end: 0;

      &::part(native) {
        box-shadow: var(--ion-color-primary-box-shadow);
        --background: orange !important;
      }

      ion-icon {
        font-size: 32px;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    ion-row {
      margin: 2rem 0;
    }

    ion-input {
      height: 50px;
      font-size: 2rem;
    }

    ion-text {
      span {
        font-size: 2rem;
      }
    }

    .bottom-btns {
      font-size: 2rem;
      font-weight: 600;
      margin-top: 4rem;
      height: 60px;
      width: 120px;
    }
  }
}

.id06-information-modal-container {
  display: flex !important;
  justify-content: center !important;
  --backdrop-opacity: 0.38 !important;
  --border-radius: 20px;

  &::part(backdrop) {
    background-color: black;
  }

  &::part(content) {
    max-height: 600px;
    max-width: 500px;
  }

  ion-content {
    --background: white;
    --padding-start: 4rem;
    --padding-end: 5rem;
    --offset-bottom: auto !important;
    --overflow: hidden;
    border-top-right-radius: 0 !important;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
    ion-col {
      margin-bottom: 2rem;
    }

    span {
      font-size: 2rem;
    }

    ion-button.close-btn {
      --box-shadow: none;
      --border-color: gray;
      --border-style: solid;
      --border-width: 1px;
      color: gray;
      font-size: 2rem;
      font-weight: 600;
      margin-top: 10px;
      height: 60px;
      width: 120px;
    }
  }
}
