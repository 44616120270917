@import 'variables';

//sm
@mixin for-phone-only {
  @media (max-width: 500px) {
    @content;
  }
}

//md
@mixin for-tablet-landscape-up {
  @media (min-width: 768px) {
    @content;
  }
}

// lg
@mixin for-desktop-up {
  @media (min-width: 992px) {
    @content;
  }
}
// xl
@mixin for-big-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin accordion-subpanel-icon-active($color) {
  ion-accordion {
    &:global(.accordion-expanded) {
      :global(.accordion-header-icon) {
        color: $color;
      }
    }
  }
}
$lightBlueSecondary: #36abe0;
