@import '../../../../../scss/mixins.scss';
ion-modal.app-default-modal {
    @include for-desktop-up {
        --height: 95% !important;
        --max-height: 900px;
        --width: 600px !important;
        --border-radius: 20px;
    }
}

ion-modal.personaloverview-modal {
    --background: transparent;
    display: flex !important;
    justify-content: center !important;
    --box-shadow: none;
    --width: 600px;
    --height: 95%;
    --backdrop-opacity: 0.8;
    @include for-phone-only {
        --width: 90%;
    }
}

ion-modal.onboarding-start-modal {
    --background: #181d3d;
    transform: none !important;
    --height: 100vh;

    ion-nav {
        --background: #181d3d;
        transform: none !important;
    }

    &::part(content) {
        transform: none;
    }

    div.ion-page {
        background: none !important;
    }

    @include for-desktop-up {
        --width: 600px;
        --height: 95%;
        background-size: 50vw;
        --border-radius: 20px;
    }
}

ion-modal.onboarding-process-modal {
    display: flex !important;
    justify-content: center !important;
    --box-shadow: none;
    --height: 100vh;
    --backdrop-opacity: 0.8;
    --background: linear-gradient(rgba(24, 29, 61, 0.8), rgba(24, 29, 61, 0.8)),
        url('../../../../../assets/images/gifs//budio_hero_illustration_for_animation_2.gif') no-repeat fixed 50% -20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;

    @include for-desktop-up {
        --width: 600px;
        --height: 95%;
        background-size: 50vw;
        --border-radius: 20px;
    }
}

ion-modal.access-info-modal {
    --background: transparent;
    display: flex !important;
    justify-content: center !important;
    --box-shadow: none;
    --width: 40%;
    --height: 80%;
    --backdrop-opacity: 0.8 !important;
    @include for-phone-only {
        --width: 90%;
    }
}

ion-modal.project-file-modal-container {
    --height: 100%;
    ion-content {
        &::part(background) {
            --height: 100%;
        }
    }
}

ion-modal.onboarding-finished-modal {
    display: flex !important;
    justify-content: center !important;
    --box-shadow: none;
    --width: 100vw;
    --height: 100vh;
    --backdrop-opacity: 0.8;
    --background: linear-gradient(rgba(24, 29, 61, 0.8), rgba(24, 29, 61, 0.8)),
        url('../../../../../assets/images/gifs//budio_hero_illustration_for_animation_2.gif') no-repeat fixed 50% -20px;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    animation: fadeInModal ease-in-out 0.5s;

    @include for-desktop-up {
        --height: 95%;
        --width: 600px;
        --border-radius: 20px;
    }

    ion-header {
        background: transparent;
    }
}

@keyframes fadeInModal {
    0% {
        margin-bottom: -20px;
        opacity: 0;
    }

    100% {
        margin: 0;
        opacity: 1;
    }
}
