@import '../../../scss/mixins.scss';
div.empty-list-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
ion-modal.multi-select-list-modal {
    --height: 90% !important;

    &::part(content) {
        padding: 16px;

        @include for-phone-only {
            padding: 0;
        }
    }
    @include for-phone-only {
        --height: 100% !important;
    }
}
