@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

html {
  --ion-dynamic-font: var(--ion-default-dynamic-font);
  --ion-font-family: 'Roboto', sans-serif;
}

body {
  font-weight: 400;
  line-height: 1.7;
  box-sizing: border-box;
}

::-webkit-input-placeholder {
  text-transform: none;
}
:-moz-placeholder {
  text-transform: none;
}
::-moz-placeholder {
  text-transform: none;
}
:-ms-input-placeholder {
  text-transform: none;
}
::placeholder {
  text-transform: none;
}

ion-content {
  --ion-background-color: var(--ion-color-light);
  border-top-right-radius: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}

ion-header {
  margin-top: calc(constant(safe-area-inset-top) + 26.5px);
}
.ion-page {
  border: none;
}

ion-header {
  background: var(--ion-color-light);
}

@mixin flexboxweb {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
