.document-form {
  // background: #f5f5f5;
}

.dropzone-section {
  height: 200px;
  width: 100%;
  background-color: var(--ion-color-light);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  gap: 8px;

  ion-icon {
    font-size: 60px;
  }
  div {
    padding: 8px;
    i {
      font-style: normal;
      font-weight: 800;
    }
  }
}
