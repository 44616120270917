ion-fab.ion-fab-position {
  top: -50px;
}

.action-button-header {
  font-size: 40px;
  position: absolute;
  z-index: 273;
  top: -19px;
  right: 0;
  display: block;
}

ion-item.input-style {
  margin-top: 10px;
}

.lable-style {
  display: block;
  margin-bottom: 20px;
  min-width: 100%;
  padding-left: 3px;
  padding-right: 3px;
}
