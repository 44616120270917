:global(.reports-page) {
  .sort-reports {
    margin-top: 10px;
    color: black;

    ion-grid {
      ion-row {
        ion-col {
          --ion-grid-column-padding: 0;
        }
      }
    }

    .documents-position {
      margin-right: 2rem;

      span {
        margin-right: 0.6rem;
      }
    }
  }

  ion-item-group.report-navigation {
    ion-grid {
      --ion-grid-padding: 0;
      box-shadow: var(--ion-color-primary-box-shadow);

      ion-col {
        --ion-grid-column-padding: 0;

        ion-item {
          --border-color: var(--ion-color-primary);
          height: 50px;

          box-shadow: var(--ion-color-primary-box-shadow);

          ion-label {
            line-height: 1 !important;
            display: flex;
            align-items: center;
            letter-spacing: normal;
            font-size: min(max(2rem), 3.2vw) !important;
            text-align: center;
          }
        }

        ion-item.active {
          --border-width: 0px 0 3px 0;
          --color: var(--ion-color-primary);
          font-weight: 600;
        }

        ion-item.not-active {
          --border-width: 0px 0 3px 0;
          --border-color: white;
          --color: lightgray;
          font-weight: 600;
        }
      }
    }
  }

  ion-item.input-container {
    border-bottom: 1px solid gray;

    ion-input {
      text-align: right;
      --placeholder-opacity: 1;
      --color: gray;
      border: none;
    }

    ion-icon {
      padding: 0;
      margin-left: 10px;
      color: gray;
    }
  }

  ion-item.active {
    --border-width: 0px 0 3px 0;
    --color: var(--ion-color-primary);
    font-weight: 600;
  }

  ion-item.not-active {
    --border-width: 0px 0 3px 0;
    --border-color: white;
    --color: lightgray;
    font-weight: 600;
  }
}

ion-grid {
  --ion-grid-padding: 2px;

  ion-col {
    --ion-grid-column-padding: 2px;

    ion-item.input-container {
      border-bottom: 1px solid gray;

      ion-input {
        text-align: right;
        --placeholder-opacity: 1;
        --color: gray;
        border: none;
      }

      ion-icon {
        padding: 0;
        margin-left: 10px;
        color: gray;
        margin-top: 1rem;
        width: 13rem;
        height: 3rem;
        font-size: 1rem;
        --border-color: gray;
        --padding-end: 2rem;
        --padding-start: 2rem;
        --border-radius: 15px;
      }
    }
  }
}

.reports-description {
  font-size: min(max(1.6rem), 3.5vw);
}
