@import '../../../../scss/mixins.scss';

.precalculations-desktop-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row-reverse;
  padding: 0 20px;
  width: 100%;
}
ion-modal.precalc-form-modal {
  --max-width: 500px;
  --min-width: auto;
  --max-width: auto;
  --height: 100%;
  --min-height: 600px;
  --max-height: 700px;
  --overflow: hidden;
  --border-radius: 30px;
  --border-width: 0;
  --border-style: none;
  --border-color: transparent;
  --background: var(--ion-background-color, var(--ion-color-dark));
  --box-shadow: none;

  @include for-phone-only {
    --height: 100vh !important;
    --max-height: 100vh !important;
    --border-radius: none;
  }
}

.search-and-sort-container {
  height: 70px;
  ion-col {
    display: flex !important;
    align-items: center !important;
  }
  .search {
    ion-item {
      margin-top: 11px;
      ion-input {
        height: 100%;
      }
    }
  }
  .sort-by {
    ion-item {
      ion-label {
        margin: 16px;
      }
    }
  }
}
.precalculations-accordion-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.showAllContainer {
  gap: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 30%;

  .colouredBox {
    width: 20px;
    height: 15px;
    background-color: var(--ion-color-tertiary);
  }

  .legendText {
    margin: 0;
    color: var(--ion-color-tertiary);
    cursor: pointer;
  }
}
