.alarmContentWrapper {
  .closeIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 30px;
  }

  .alarmContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .sectionTopContainer {
      max-height: 350px;
      padding: 6rem 2rem 5rem 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      .topContainerInner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;

        ion-icon {
          color: var(--ion-color-light);
          font-size: 100px;
        }

        h2 {
          font-weight: 600;
          font-size: 32px;
          color: var(--ion-color-light);
          margin: 0;
        }

        h3 {
          font-weight: 800;
          margin: 0;
        }
      }
    }

    .sectionBottomContainer {
      h4 {
        max-width: 300px;
        font-size: 24px;
        text-align: center;
      }
    }
  }

  .bottomButtonsContainer {
    position: absolute;
    bottom: 10px;
    width: 100%;
    padding: 16px;
  }
}
