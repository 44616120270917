ion-modal.create-list-modal-container {
  ion-header.create-list-view-header {
    ion-toolbar {
      display: flex;
      align-items: center;
      height: 80px;

      ion-title {
        h1,
        ion-icon {
          margin: 8px 0 0 16px;
          font-size: 34px;
        }
        h1 {
          border-left: 8px solid var(--ion-color-tertiary);
        }
      }
    }
  }
  ion-grid.add-item-container {
    ion-item {
      border-radius: 8px;
      --detail-icon-opacity: 1;
      --detail-icon-color: var(--ion-color-primary);
    }
  }
}
ion-item.list-item-toggled {
  --background: rgba(159, 251, 159, 0.66);
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
  --detail-icon-color: var(--ion-color-medium);
  border-radius: 10px;
}
ion-item.list-item-untoggled {
  --background: var(--ion-color-light);
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
  --detail-icon-color: var(--ion-color-medium);
  border-radius: 10px;
}

ion-modal.datetime-picker-modal {
  &::part(content) {
    height: 320px;
    width: 320px;
    background-color: wheat;
  }
}
div.date-time-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  ion-icon {
    font-size: 24px;
  }

  ion-datetime-button.datetime-picker-button {
    &::part(native) {
      background-color: white;
      font-size: 16px;
      border-bottom: 1px solid black;
      border-radius: 0;
    }
  }
}

.working-environment-wrapper {
  ion-fab-button {
    --box-shadow: none;
  }
}

.working-environment-is-checked {
  border: 2px solid var(--ion-color-medium) !important;
  border-radius: 50%;
}

.working-environment-slider-is-checked {
  animation: slide 0.1s forwards;
}

.working-environment-vam {
  vertical-align: middle;
}

@keyframes slide {
  0% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
