.information-button {
  ion-button {
    --box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
    width: 26px;
    height: 26px;
    --border-radius: 50%;

    margin: 0 0 0 25px;
    width: 40px;
    height: 40px;
    --padding-start: 0;
    --padding-end: 0;

    ion-icon {
      color: blue;
    }
  }
}

.item-has-focus > ion-label {
  color: red !important;
}

.add-id06 {
  font-size: 1.7rem;
  margin-right: 10px;
}
