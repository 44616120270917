.evenRow {
  background-color: var(--ion-color-light);
  width: 100%;
}
.precalculations-container {
  ion-label {

    letter-spacing: 1px;
    color: gray;
    margin: 0;
  }

  section {
    display: flex;
    gap: 16px;
    flex-direction: column;
  }

  .precalculations-row-container {
    background-color: var(--ion-color-light);

    ion-row {
      ion-col {
        margin: 0;

        p {
          margin: 0;
        }
      }
    }

    .precalculations-comment-section {
      h4 {
        color: red;
        transition: 0.6s ease-in-out;
      }
    }
  }
}

.date-locked {
  color: #edac53 !important;
}

.date-unlocked {
  color: green !important;
}

.pointer {
  cursor: pointer;
}

.precalculations-comment-section {
  span {
    // font-size: 1.6rem;
    line-height: 1.5;
  }
}
