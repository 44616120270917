section.form-container {
  width: 100%;
}
ion-list.form-content {
  margin-top: 30px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
