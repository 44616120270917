ion-grid.swiper-nav-container {
  padding-right: 10px;

  .cash-flow-slide-btn,
  .cash-flow-slide-btn-disabled,
  .payment-schedule-slide-btn,
  .payment-schedule-slide-btn-disabled {
    width: 32px;
    height: 32px;
    --border-radius: 50%;
    --box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);
    --padding-end: 0;
    --padding-start: 0;
    --padding-top: 0;
    --padding-bottom: 0;
  }

  .cash-flow-slide-btn {
    --background: white;
    --background-hover: var(--ion-color-primary);
    --ripple-color: var(--ion-color-secondary);

    ion-icon {
      color: orange;
      font-size: 28px;
    }
  }
  .cash-flow-slide-btn-disabled {
    --background: rgb(194, 194, 194);

    ion-icon {
      color: white;
    }
  }

  .payment-schedule-slide-btn {
    --background: white;
    --background-hover: var(--ion-color-primary);
    --ripple-color: var(--ion-color-primary);

    ion-icon {
      color: rgb(0, 225, 255);
      font-size: 16px;
    }
  }

  .payment-schedule-slide-btn-disabled {
    --background: rgb(194, 194, 194);

    ion-icon {
      color: white;
    }
  }
}
