/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
/* TINT IS HOVER */
:root {
  /** BANKID **/
  --ion-color-bankid: #183e4f;

  /** MAX WIDTHS & HEIGHTS **/
  --ion-desktop-max-width: 1600px;
  --ion-desktop-mid-width: 800px;
  --ion-modal-max-width: 600px;
  --ion-modal-max-height: 800px;

  /** FONT SIZES **/
  --ion-font-size-xxs: 0.8rem;
  --ion-font-size-xs: 1rem;
  --ion-font-size-sm: 1.2rem;
  --ion-font-size-md: 1.4rem;
  --ion-font-size-lg: 1.6rem;

  --ion-label-size-small: 0.8rem;
  --ion-label-size-medium: 1.5rem;

  /** PRIMARY **/
  --ion-color-primary: #5d7292;
  --ion-color-primary-light: #6b6e83;
  --ion-color-primary-rgb: 93, 114, 146;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #526480;
  --ion-color-primary-tint: #6d809d;

  /** PRIMARY GRADIENT **/
  --ion-color-primary-gradient: linear-gradient(180deg, rgba(93, 114, 146, 0) 0%, rgba(93, 114, 146, 0) 100%);
  --ion-desktop-color-box-shadow: 0px 6px 5px -2px rgba(179, 179, 179, 1);
  --ion-color-dark-primary: #181d3d;
  --ion-color-light-box-shadow: 0px -10px 20px 14px rgb(182, 182, 182);
  --ion-color-primary-box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);

  --custom-color-light-blue-primary: #50c8ff;
  --custom-color-light-blue-secondary: #36abe0;

  /** secondary **/
  --ion-color-secondary: #edac53;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #425269;
  --ion-color-secondary-tint: #7c98c4;

  /** Onboarding color scheme **/
  --ion-color-onboarding-red: #f65364;
  --ion-color-onboarding-dark: #181d3d;
  --ion-color-onboarding-dark-overlay: rgb(19, 6, 6);

  /** tertiary **/
  --ion-color-tertiary: #181d3d;
  --ion-color-tertiary-rgb: 24, 29, 61;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #151a36;
  --ion-color-tertiary-tint: #2f3450;

  /** success **/
  --ion-color-success: #60c65e;
  --ion-color-success-rgb: 96, 198, 94;
  --ion-color-success-contrast: #fffff;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #54ae53;
  --ion-color-success-tint: #70cc6e;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #8b8989;
  --ion-color-medium-rgb: 72, 72, 72;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #3f3f3f;
  --ion-color-medium-tint: #5a5a5a;

  /** light **/
  --ion-color-light: #f6f6f6;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #8b8989;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #c8c8c86a;
}

/* Here we can switch to dark mode or light mode*/
// @media (prefers-color-scheme: dark) {
/*
  * Dark Colors
  * -------------------------------------------
*/

body {
  // E2C CUSTOM //
  --ion-color-light-box-shadow: 0px -10px 20px 14px rgb(26, 26, 26);
}
body.dark {
  // E2C CUSTOM //
  --ion-color-light-box-shadow: 0px -10px 20px 14px rgb(26, 26, 26);
  --ion-color-primary-gradient: linear-gradient(180deg, rgba(26, 23, 11, 0.507) 0%, rgba(26, 23, 11, 0.507) 100%);

  // ! E2C CUSTOM //
  --ion-color-primary: #428cff;
  --ion-color-primary-rgb: 66, 140, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

/*
   * iOS Dark Theme
   * -------------------------------------------
   */

.ios body {
  --ion-background-color: white;
  --ion-background-color-rgb: 0, 0, 0;
}
.ios body.dark {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #00000053;

  --ion-card-background: #1c1c1d;
}

.ios ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}

.ios ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body {
  --ion-background-color: white;
  --ion-background-color-rgb: 18, 18, 18;
}
.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #1e1e1e;

  --ion-card-background: #1e1e1e;
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}
