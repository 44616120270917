ion-header.finished-header {
  height: 25vh;
  background: transparent;
}

ion-toolbar {
  --background: transparent;

  ion-title {
    height: 100%;
  }
}

ion-content.finish-content {
  border-top-left-radius: 30px;
  height: 100%;

  div.finish-container {
    animation: fadeInFormAnimation ease-in-out 1.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    background: var(--ion-color-light);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 30px;
    flex-direction: column;

    ion-text {
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: var(--ion-color-light);
      border-bottom-left-radius: 20px;

      h3 {
        padding: 1rem 0;
        margin: 0;
      }

      h5 {
        padding: 1rem 3rem;
      }
    }
  }
}

@keyframes fadeInFormAnimation {
  0% {
    margin-left: -10px;
    opacity: 1;
  }
  100% {
    margin: 0;
    opacity: 1;
  }
}

div.finish-bottom-container {
  height: 20vh;
  width: 100%;
  animation: fadeInToolbar ease-in-out 0.8s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  div.bottom-btn-container {
    border-top-left-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: var(--ion-color-dark-primary);

    ion-button {
      background: var(--ion-color-danger);
      width: 120px;
      height: 40px;
      border-radius: 10px;
      font-weight: 400;
    }
  }
}

@keyframes fadeInToolbar {
  0% {
    margin-right: 20px;

    opacity: 0;
  }
  100% {
    margin: 0;
    opacity: 1;
  }
}

div.finish-gif {
  background-color: var(--ion-color-dark-primary);

  ion-img {
    background: rgb(24, 29, 61);
    background: linear-gradient(0deg, rgba(24, 29, 61, 1) 50%, rgba(255, 255, 255, 1) 52%);
    height: 100%;

    &::part(image) {
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      height: 100%;
    }
  }
}
