ion-grid.cashflow-calculate {
  padding: 0;

  ion-label {
    margin-left: 0;
    font-size: 12px;
    letter-spacing: 1px;
  }

  ion-datetime-button {
    margin-left: 0;
    font-size: 16px;

    &::part(native) {
      text-align: left;
      width: 100%;
      border-radius: 0;

      border-bottom: 2px solid var(--ion-color-medium);
      background-color: transparent;
      padding: 8px 0 !important;
    }
  }
}
ion-datetime-button::part#time-button {
  display: none !important;
}
