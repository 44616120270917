#curve-box {
  position: relative !important;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 30px 0 0 0px !important;
  text-align: center;
  color: #fff;

  #curve-top,
  #curve-bottom {
    position: absolute !important;
    height: 30px;
    width: 30px;
    right: 0;
    overflow: hidden;
  }

  #curve-top,
  #curve-bottom {
    &::before {
      content: '';
      position: absolute;
      right: 0;
      height: 200%;
      width: 200%;
      border-radius: 100%;
      box-shadow: 10px 10px 5px 100px white;
      z-index: -1;
    }
  }

  #curve-top {
    top: -30px;

    &::before {
      top: -100%;
    }
  }

  #curve-bottom {
    bottom: -30px;
  }
}
