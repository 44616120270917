ion-card-header.card-header {
  background: var(--ion-color-primary-gradient);
  border-radius: 15px 15px 0 0;
  padding: 1.5rem;

  ion-grid {
    --ion-grid-padding: 0;

    ion-row {
      ion-col {
        --ion-grid-column-padding: 0;
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: min(max(3rem), 6vw) !important;
        color: white !important;

        .card-title {
          font-size: min(max(3rem), 6vw) !important;
          font-weight: 600;
        }

        ion-icon {
          cursor: pointer;
        }
      }
    }
  }
}
