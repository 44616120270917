@import '../../../../scss/mixins.scss';

#control-of-execution-label-small {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5;
}

ion-modal.control-of-execution-modal-container {
    --max-height: 90vh;
    --max-width: 600px;

    &::part(content) {
        padding: 16px;

        @include for-phone-only {
            padding: 0;
        }
    }
    @include for-phone-only {
        --height: 100% !important;
    }
}

div.project-information-accordion-container {
    ion-accordion {
        &:global(.accordion-expanded) {
            :global(.accordion-header-icon) {
                color: var(--ion-color-secondary) !important;
            }
        }
    }
}

ion-header.record-view-header {
    ion-toolbar {
        display: flex;
        align-items: center;
        height: 80px;

        ion-title {
            h1,
            ion-icon {
                margin: 8px 0 0 16px;
                font-size: 34px;
            }
            h1 {
                border-left: 8px solid var(--ion-color-tertiary);
            }
        }
    }
}

div.record-view-actions {
    display: flex;
    height: 60px;
    gap: 1rem;
}

form.record-view-edit-form {
    ion-grid {
        ion-col.select-container {
            ion-select {
                border-bottom: 0.1px solid var(--ion-color-medium) !important;
            }
        }
        ion-row.form-section {
            gap: 2rem;
        }
    }
}

ion-content.upload-record-files-modal-content {
    ion-grid {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        ion-row {
            width: 80%;
        }
    }
}
