ion-card.report-card {
  border-radius: 0;
  margin-left: -10px;
  margin-right: -10px;
  padding-right: 0;
  height: 100%;
}

ion-card.reports-card {
  min-height: 90vh;
  color: white;
  border-radius: 15px;

  ion-card-content.ion-report-card-content {
    padding: 0;
  }
}

ion-card-content.ion-card-content {
  padding: 0;
}

.open-icon {
  padding-left: 5px;
  border-left: solid 0.65px #8080808c;
  height: 65%;
  color: #808080db;
}

.empty-list {
  color: black;
}
