@import './scss/mixins';

.toast-holder {
  padding: 20px;
}

#fade-in-page {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

ion-content.remove-scroll {
  --offset-bottom: auto !important;
  --overflow: hidden;
  background-color: var(--ion-color-light);
  overflow: auto;

  ion-refresher {
    z-index: 12355152512;
    position: static !important;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

ion-item {
  --background: var(--ion-color-none);
}

p {
  color: var(--ion-color-dark-shade);
}

ion-button {
  text-transform: uppercase;
}
