@import '../../../scss//mixins.scss';

ion-button.ion-button-round {
  --padding-start: 6px;
  --padding-end: 6px;
  border-radius: 50%;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
}

ion-button.ion-button-cta {
  word-break: break-all;

  ion-icon {
    margin: 0 0 0 8px;

    &.left-icon {
      margin: 0 8px 0 0;
    }
  }
}

.segmentButtons {
  overflow-x: scroll;
  overflow-y: hidden;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-radius: 0 0 30px 30px;
}
