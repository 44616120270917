@import '../../../../../scss/mixins.scss';

ion-text.summary-title {
  h3 {
    font-weight: 600;
    color: var(--ion-color-primary-shade);
  }
}

ion-grid.onboarding-summary-container {
  margin-bottom: 40px;
  ion-item {
    &::part(native) {
      background: transparent;
    }

    ion-row {
      width: 100%;
    }
  }

  div.personal-information-list {
    margin-bottom: 40px;
  }

  ion-col.section-summary-title {
    h6 {
      color: var(--ion-color-dark);
      font-weight: 600;
    }
  }

  ion-list.certificate-section {
    ion-item {
      margin: 20px 0;
    }

    .document-row {
      margin: 30px 0;

      ion-icon {
        color: var(--ion-color-medium);
      }

      p {
        padding-left: 1rem;
        color: var(--ion-color-medium);
      }
    }
  }
}
