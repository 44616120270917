div.site-access-requests-wrapper {
    opacity: 0;
    animation-name: fadein;
    animation-duration: 500ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-delay: 200ms;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
