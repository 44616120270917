div.pageWrapper {
  display: flex;
  justify-content: center;
  div.widthContainer {
    padding-top: 20px;
    width: 100%;
    ion-grid.rowContainer {
      max-height: 600px;
      overflow-y: scroll;
      ion-label.labelStyle {
        color: gray;
        font-weight: 600;
        letter-spacing: 0.1rem;
        padding: 0;
      }
    }
  }
}
