ion-icon.lock-animation {
    animation: wiggle 1s linear;
    opacity: 1;
}

@keyframes wiggle {
    from {
        transform: 1;
        opacity: 0;
    }

    to {
        transform: scale(1, 1);
    }

    25% {
        transform: scale(0.9, 1.1);
        opacity: 0.3;
    }

    50% {
        transform: scale(1.1, 0.9);
        opacity: 0.5;
    }

    75% {
        transform: scale(0.95, 1.05);
        opacity: 0.7;
    }
}
