ion-card.report-list-card {
  background-color: white;
  border-radius: 0;

  padding-right: 0;
  height: 100%;

  ion-card-content.ion-list-card-content {
    padding: 0;

    ion-grid {
      --ion-grid-padding: 0;

      ion-row {
        display: flex;
      }

      ion-col {
        display: flex;
        align-items: center;
        font-size: 2rem;
        color: var(--ion-color-primary);
        cursor: pointer;

        ion-item {
          .observation-name {
            font-size: min(max(2rem), 5vw) !important;

            letter-spacing: normal;
          }
        }

        ion-text {
          font-size: min(max(2rem), 5vw) !important;
        }
        ion-icon {
          font-size: min(max(2rem), 5vw) !important;

          margin-left: 0.3rem;
          margin-right: 0.5rem;
          margin-top: -2px;
        }
      }

      ion-label {
        color: gray;
      }
    }
  }
}

.open-icon {
  padding-left: 5px;
  border-left: solid 0.65px #8080808c;
  height: 65%;
  color: #808080db;
}
.empty-list {
  color: black;
}
