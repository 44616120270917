@import '../../../scss/mixins.scss';
// SHADOW CONTAINER
ion-item.item-shadow-container {
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
    max-width: 1500px;
    cursor: pointer;

    &::part(native) {
        --padding-start: 4px;
    }

    div {
        padding: 0;
        max-width: 100%;
    }
}

span.item-background-preview {
    display: block;
    width: 45px;
    height: 45px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 4px;
}

// ITEM CONTENT HEADER
ion-grid.item-content-header-wrapper {
    width: 100%;

    ion-label.child-text {
        font-size: 14px;
        margin-bottom: -5px;
    }

    ion-label.child-label {
        color: dimgray;
        margin: 0;
        font-size: 11px;
        height: 14px !important;
        font-weight: 400;
        line-height: 1.5;
    }

    ion-label.child-sublabel {
        color: dimgray;
        margin: 0;
        font-size: 11px;
        height: 18px !important;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 1px;
    }

    ion-item.item-content-header-content {
        --min-height: fit-content;

        ion-label.child-text {
            font-size: 20px;
            margin: 0;
        }

        &::part(native) {
            --padding-start: 0;
        }
    }

    section.item-content-icon-container {
        display: flex;
        justify-content: flex-end;
        gap: 8px;

        div {
            display: flex;
            align-items: center;
            color: dimgray;
            font-size: 14px;
            gap: 2px;
        }

        ion-icon {
            font-size: 28px !important;
        }
    }
}

ion-item.item-with-total-container {
    margin-left: 4px;
}

.item-with-total-label {
    font-size: 12px !important;
    color: var(--ion-color-medium) !important;
    @include for-desktop-up {
        font-size: 18px !important;
    }
}
.item-with-total-description {
    @include for-desktop-up {
        font-size: 18px !important;
    }
}
ion-grid.item-no-icon-grid {
    ion-label {
        font-size: 1.2rem;
    }

    ion-button {
        &::part(native) {
            background: none;
        }
    }
}
