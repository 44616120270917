div.onboarding-stage-three {
  margin: 50px 0 0 0;

  ion-label.tap-to-open-document-label {
    color: var(--ion-color-medium);
    font-weight: 600;
  }

  ion-card {
    ion-card-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      ion-text {
        ion-label {
          font-size: medium;
          font-weight: 600;
        }
      }
    }
  }

  ion-checkbox {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
