@import '../../scss/basics.scss';

.register-steps{
  z-index: 100;
  width: 100%;
  margin-bottom: 30px;
  padding: 10px;
  text-align: center;

  ion-list.steps-list {
    justify-content: center;
    @include flexboxweb;
    span{
      display: block;
      width: 35px;
      height: 35px;
      padding: 10px;
      border-radius: 50%;
      background-color: lightgrey;
        &:not(:first-child){
          margin-left: 30px;
        }
    }
  }
  
}

ion-list.steps-list{
  span.active{
    background-color: orange;
  }
}