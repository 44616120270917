@import '../../../scss/mixins.scss';

ion-modal.ion-modal-small-container {
    --height: 400px !important;
    --width: 400px !important;
    --border-radius: 16px;

    &::part(backdrop) {
        --backdrop-opacity: 0.4;
    }

    @include for-phone-only {
        --height: 600px !important;
    }
}

ion-header.record-view-header {
    background: var(--ion-color-light);
    ion-toolbar {
        margin-top: calc(constant(safe-area-inset-top) + 26.5px);
        display: flex;
        align-items: center;

        ion-title {
            h1,
            ion-icon {
                margin: 8px 0 0 16px;
                font-size: 34px;
            }
        }
    }
}
