.registration-header-container {
  --ion-color-primary-gradient: linear-gradient(180deg, rgba(29, 50, 82, 0.5) 0%, rgb(35, 50, 73, 0.5) 100%);
  box-shadow: var(--ion-color-light-box-shadow);
  background-image: var(--ion-color-primary-gradient), url('../../../assets//images/Project_tools_desktop.jpg');
  background-size: cover;
  height: 200px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  h2 {
    letter-spacing: 1px;
    text-align: center;

    color: var(--ion-color-light);
    font-weight: 600;
    font-size: 32px;
  }

  h5 {
    font-size: 18px;
    color: var(--ion-color-light);
    text-align: center;
    margin: 5px 0;
  }
}
