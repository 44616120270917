div.team-form-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  div.team-section-container {
    width: 100%;
  }

  form {
    div.team-form-buttons-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
    }
  }
}
