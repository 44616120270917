.vertical-aligned-grid {
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
}

.login-container {
  border-top-right-radius: 0 !important;
}
.appVersionContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.login-form-container {
  width: 350px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.app-version-container-login-page {
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
