ion-toolbar.step-btns-container {
    padding: 30px, 0, 30px;
    margin-bottom: 30px;
}
ion-grid {
    ion-row {
        ion-button.left-step-btn,
        ion-button.right-step-btn {
            font-weight: 500;
            color: gray;
        }
        ion-col {
            ion-button.left-step-btn {
                color: gray;
                --box-shadow: none;
                --border-color: none;
                --border-width: 0;
            }
        }
    }
}
