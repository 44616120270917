.split-pane-wrapper {
    margin-bottom: 120px;
    .split-pane-category-list-item {
        font-size: 16px;
        display: block;
        margin-bottom: 10px;
        text-decoration: none;

        div.label-container {
            display: 'flex';
            align-items: 'center';
            gap: 20;
        }
    }
}
