ion-grid.emptyListWrapper {
  section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ion-icon {
    opacity: 0.2;
    color: var(--ion-color-medium);
  }
}
