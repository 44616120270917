.create-user-from-invite-form{
    text-align: center;
    
  ion-list.form-content{
    width: 80%;
    margin: 0 auto;
  }
  
}

ion-text.already-registered{
  font-size: 1.4rem;
}
