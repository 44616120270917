div.bankIdContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  div.bankIdQrContainer {
    margin-top: 30px;
    min-height: 200px;
  }
  div.bankIdPlaceHolderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 200px;
  }
}
