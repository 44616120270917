.observation-edit {

  ion-grid,
  ion-row,
  ion-col {
    --ion-grid-column-padding: 0;
    --ion-grid-padding: 0;
  }

  ion-grid {
    --ion-grid-padding: 0;

    ion-label {
      color: black;
    }

    ion-col {
      --ion-grid-column-padding: 0;

      ion-row.input-container {
        margin-bottom: 2rem;

        ion-input,
        ion-textarea {
          font-size: min(max(2.6rem), 5vw) !important;
          border-bottom: 1px solid black;
        }

        ion-input {
          margin-bottom: 10px;
          --padding-bottom: 5px;
        }

        ion-textarea {
          --padding-bottom: 0;
          --padding-top: 0;
        }
      }
    }
  }
}