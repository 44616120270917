#graph-container {
  width: '100%';
  overflow-x: 'scroll';
  overflow: 'hidden';
}

#graph-container::-webkit-scrollbar {
  display: none;
}

#graph-container {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

div.chart-container {
  width: '100%';
  overflow-x: 'scroll';
  scroll-behavior: 'smooth';
  overflow-y: 'hidden';
  scrollbar-color: 'none';
}

ion-list.swipe-list-odd {
  ion-item {
    &::part(native) {
      --background: var(--ion-color-light);
    }
  }
}

ion-list.swipe-list {
  ion-item {
    --color: rgb(112, 112, 112);
    font-weight: 400;
    --border-color: white;
    border-bottom-style: dotted;
    border-bottom-color: rgb(207, 207, 207);
  }
}
