ion-accordion-group.accordion-group-container-desktop,
ion-accordion-group.accordion-group-container {
  ion-accordion {
    &:global(.accordion-expanded) {
      ion-item {
        background-color: var(--ion-color-light);
      }
    }
  }

  ion-item {
    ion-icon {
      &:nth-child(odd) {
        &:nth-child(3) {
          text-align: end !important;
          margin: 0 0 0 8px;
        }
      }
    }
    ion-text {
      p {
        min-width: 150px;
        margin: 0 0 0 16px;
      }
    }
  }
}

ion-accordion-group.accordion-group-container-desktop {
  width: 100vw;
}

ion-list.swipe-list-odd {
  ion-item {
    &::part(native) {
      --background: var(--ion-color-light);
    }
  }
}

ion-list.swipe-list {
  ion-item {
    --color: rgb(112, 112, 112);
    font-weight: 400;
    --border-color: white;
    border-bottom-style: dotted;
    border-bottom-color: rgb(207, 207, 207);
  }
}

.swiper-hover {
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: var(--ion-color-primary);
    background-color: var(--ion-color-light);

    ion-item {
      &::part(native) {
        background-color: var(--ion-color-light);
      }
    }
  }
}
