.page-container{
  text-align: center;
    
  ion-list.form-content{
    width: 80%;
    margin: 0 auto;
  }

  ion-list.image-container{
    width: 50%;
    max-width: 250px;
    margin: 0 auto;
  }
  
  
}


