.user-settings-form-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin-bottom: 34px;
}
.user-setting-form-container {
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: center;
    margin: 16px 0 0 0;
    ion-button {
        width: 120px;
    }
    ion-col.avatar-container {
        margin-bottom: 16px;
        cursor: pointer;

        ion-avatar {
            width: 70px;
            height: 70px;
        }
        ion-icon {
            position: absolute;
            font-size: 24px;
            bottom: 0;
            left: 50px;
            background-color: var(--ion-color-success);
            border-radius: 50%;
            padding: 4px;
        }
    }
}
.reset-password-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid orange;
    width: 80%;
}
.support-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid orange;
    width: 80%;
}
