@import '../../../../../scss/mixins.scss';

.onboarding-stage-two {
  margin: 5px;
  height: 100%;
  form {
    ion-toggle {
      --track-background-checked: var(--custom-color-light-blue-primary);
      --handle-background-checked: var(--custom-color-light-blue-secondary);
    }
  }

  .fade-in-upload-section {
    animation: fadeInFormAnimation ease 1.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    @keyframes fadeInFormAnimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  .add-other-cert-container {
    ion-button {
      --box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
      --border-radius: 50%;
      width: 30px;
      height: 30px;
      --padding-start: 0;
      --padding-end: 0;
    }
  }

  ion-card.uploaded-document-card {
    width: 100%;
    margin: 2rem 0 2rem 0;
    padding: 1rem;
    --background: transparent;

    ion-col {
      display: flex;
      align-items: center;

      &:nth-child(1) {
        justify-content: flex-start;
      }

      &:nth-child(2) {
        justify-content: flex-end;
      }
    }
  }
}

div.datepick-container {
  border-bottom: 0.5px solid gray;
  border-width: 50%;
  margin-bottom: 16px;
  margin-left: 16px;
  width: 80%;
  padding-top: 16px;

  ion-datetime-button {
    &::part(native) {
      padding: 0;
      background: transparent;
      width: 100%;
      text-align: left;
      border-radius: 0;
    }
  }
}

ion-modal.date-modal-container {
  background: #181d3d83;
  &::part(content) {
    max-width: 300px;
    max-height: 400px;
  }

  ion-item.calendar-container {
    --padding-start: 0;
    --inner-padding-end: 0;
  }
}

ion-grid.confirm-stage-two-container {
  margin-top: 4rem;
}
