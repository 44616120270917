.dateCol {
  font-weight: 700;
  font-size: var(--ion-font-size-xs);
}

ion-text.noticeTextContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
