@import '../../scss/mixins.scss';

.page-menu-header-comp {
  ion-title.header-title {
    color: white;
  }

  &ion-header.golden-header {
    .segment-button-indicator {
      display: none;
    }

    &::after {
      display: none;
    }
  }

  ion-segment.golden-segment {
    min-height: 34px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    --background: transparent;

    ion-segment-button {
      min-width: 33.3%;
      min-height: 34px;
      color: white;
      text-transform: uppercase;
      --padding-end: 16px;
      --padding-start: 16px;
      border-radius: 8px;
      --ion-toolbar-segment-background-checked: rgba(70, 74, 99, 0.5);

      &::before {
        display: none;
      }

      &::part(indicator-background),
      &::part(native) {
        border-radius: 8px;
      }

      &::part(indicator) {
        display: none;
      }

      ion-label {
        margin: 0;
        width: 100%;
        font-size: 14px;
      }
    }
  }

  ion-toolbar.header-toolbar-background {
    box-shadow: var(--ion-color-light-box-shadow);
    --background: rgba(0, 0, 0, 0.417);
    z-index: -1;
    background-repeat: no-repeat;
    width: 100%;
    border-radius: 0 0 30px 30px;
    background-position: top !important;
    background-size: cover !important;
  }

  &.golden-header {
    :global(.icon-select) {
      border-bottom: white 1px solid;
      width: 100%;

      ion-item {
        --background: transparent;
        --color: white;

        ion-icon {
          color: white;
        }
      }
    }
  }

  ion-item div.item-native {
    --background: transparent;
  }

  ion-item div.item-native {
    --background: transparent;
  }

  ion-buttons.image-center {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;

    ion-item {
      display: flex;
      justify-content: center;
    }
  }

  ion-avatar {
    position: absolute;
    left: 40%;
  }

  ion-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    ion-icon.avatar-icon {
      position: relative;
      z-index: 20;
      width: 20px;
      height: 20px;
      background-color: var(--ion-color-success);
      border-radius: 50%;
      margin-left: 70px;
      margin-top: 10px;
      font-size: 20px;
      color: black !important;
    }
  }

  .header-nav {
    position: relative;
    display: block;
    width: 100%;
    padding: 0 0 15px 0;
    margin: 0 auto;
    max-width: 380px;
  }

  ion-item.project-selector {
    ion-select {
      color: white;
      width: 100%;
      border-bottom: solid 1px;
      padding-bottom: 5px;
      padding-left: 12px;
    }

    margin-top: 12px;
  }
}

.header-user,
.header-bar {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: contain;
  background-position: center;
  line-height: 0;
  background-repeat: no-repeat;

  &.header-user {
    aspect-ratio: 310/50;
    background-image: url(../../../src/assets/images/svg/MenuAvatar.svg);
  }

  &.header-bar {
    aspect-ratio: 310/53;
    background-image: url(../../../src/assets/images/svg/MenuBar.svg);
  }
}
