ion-item.sort-by-dropdown {
    ion-select {
        width: 100%;
        &::part(placeholder),
        &::part(text),
        &::part(icon) {
            margin-bottom: 12px;
        }
    }
}
