ion-modal.preview-modal-container {
  --width: 95%;
  --height: 95%;
  &::part(content) {
    border-radius: 0;
  }
  .preview-container {
    width: 100%;
    max-height: 100%;
    ion-img.preview-image {
      width: 100%;
      height: 100vh;
    }
  }
}

ion-item.item-inner-padding-end {
  --inner-padding-end: 10px;
}
