.ion-padding {
  display: flex;
  margin: 15px 5px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;

  ion-icon {
    align-self: flex-end;
  }

  .item-label {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}

.card-ion-list {
  width: 100%;
  margin-top: 30px;
}

ion-fab.plus-button {
  --background: var(--ion-color-primary);
  position: relative;
}

ion-fab-button {
  margin-top: 25px;
}
