ion-accordion-group.accordion-group-container-desktop,
ion-accordion-group.accordion-group-container {
  ion-accordion {
    &:global(.accordion-expanded) {
      ion-item {
        background-color: 'var(--ion-color-light) !important';
      }
      :global(.accordion-header-icon) {
        color: var(--accordion-icon-active) !important;
      }
    }
  }

  ion-item {
    ion-icon {
      &:nth-child(odd) {
        &:nth-child(3) {
          text-align: end !important;
          margin: 0 0 0 8px;
        }
      }
    }
    ion-text {
      p {
        min-width: 150px;
        margin: 0 0 0 16px;
      }
    }
  }
}

ion-accordion-group.accordion-group-container-desktop {
  width: 100vw;
}
