ion-modal.related-search-modal {
  --min-height: 200px;
  --max-height: 900px;
  ion-content {
    --padding-top: 0;
    --padding-bottom: 0;

    ion-searchbar.searchbar {
      // margin: 20px 5px;
    }
    ion-list.related-search-list {
      overflow-y: scroll;
      border: 2px solid var(--ion-color-light);
      border-radius: 20px;

      ion-label.no-more-results {
        font-weight: 800;
        letter-spacing: '1px';
        font-size: '12px';
        text-align: center;
        width: 100%;
        opacity: 0.8;
      }
    }
  }

  ion-header {
    padding-top: 2rem;
  }
}
