.fade-in-form {
  animation: fadeInFormAnimation ease 1.8s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInFormAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#onboarding-header-container {
  ion-header {
    background: transparent;
  }

  ion-toolbar {
    --background: transparent;

    h5 {
      margin: 0.5rem 0 0 0;
      color: var(--ion-color-light);
      text-align: center;
    }

    div.onboarding-progress {
      align-items: center;
      border-radius: 30px;
      display: flex !important;
      justify-content: center !important;
      min-width: 80%;
      max-width: 70%;
      height: 50px;
      color: var(--ion-color-light);
      background: var(--ion-color-primary);
      opacity: 0.7;
      font-weight: 600;
      font-size: 1rem;
      padding: 1rem;
      margin: 1rem 0 2rem 0;

      p.active {
        color: var(--ion-color-light);
        font-size: 1.3rem;

        font-weight: 600;
        animation: fadeInFormAnimation ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        margin: 0;
      }
    }
  }
}

ion-content.onboarding-form-content-container {
  height: 100%;
  --offset-bottom: auto !important;
  --overflow: hidden;
  overflow: auto;
  bottom: 0;

  &ion-content::part(background) {
    --background: transparent;
    bottom: 0;
  }
}
