.cashflow-container {
  --background: orange;
  --border: 2px solid red;
}
.suggested {
  ion-list {
    ion-item {
      --background: none;
    }
  }
}

.select-month {
  p {
    font-size: 1.2rem;
    margin: 0;
  }
}
ion-label {
  margin-left: 16px;
}

.Suggested-line-border {
  border-left: 2px solid blue;
  margin: 10px 0 0 0;
}

.Manual-line-border {
  border-left: 2px solid orange;
  margin: 10px 0 0 0;
}
.Manual-hover {
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: var(--ion-color-primary);
    background-color: var(--ion-color-light);

    ion-item {
      &::part(native) {
        background-color: var(--ion-color-light);
      }
    }
  }
}

ion-list {
  background-color: transparent !important;
}

.disabled-btn {
  --background: var(--ion-color-danger-tint);
  opacity: 0.3;
}
ion-list.cash-flow-row-list-odd {
  ion-item {
    &::part(native) {
      --background: var(--ion-color-light);
    }
  }
}

ion-list.cash-flow-row-list {
  ion-item {
    --color: rgb(112, 112, 112);
    font-weight: 400;
    --border-color: white;
    border-bottom-style: dotted;
    border-bottom-color: rgb(207, 207, 207);
  }
}
ion-col.difference {
  border-radius: 10px;
  p {
    text-align: center;
    font-size: 12px;
    color: var(--ion-color-light);
  }
}
ion-list.cash-flow-prop-list {
  margin-left: 5px;

  ion-label {
    color: rgb(63, 63, 63);
    margin-left: 5px;
  }
  ion-item {
    --border-color: white;
    border-bottom-style: solid;
    border-bottom-color: rgb(207, 207, 207);
    margin-left: 5px;
    --color: rgb(112, 112, 112);
    font-weight: 400;
  }
}
