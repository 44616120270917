.fade-in {
  animation: fadeIn 0.2s;
  margin-top: 25px;
}
.content-background {
  --background: transparent;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
div.logo-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  flex-direction: column;
  img {
    width: 100px;
    height: 100px;
  }
  h1 {
    font-size: var(--ion-font-size-lg);
    text-align: center;
    margin-top: 16px;
    font-weight: 800;
  }
  h3 {
    font-size: var(--ion-font-size-sm);
    text-align: center;

    margin-top: 8px;
  }
}
.user-setting-form-container {
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: flex-end;
  margin: 16px 0 0 0;
  ion-button {
    width: 120px;
  }
  ion-col.avatar-container {
    margin-bottom: 16px;
    cursor: pointer;

    ion-avatar {
      width: 70px;
      height: 70px;
    }
    ion-icon {
      position: absolute;
      font-size: 24px;
      bottom: 0;
      left: 50px;
      background-color: var(--ion-color-success);
      border-radius: 50%;
      padding: 4px;
    }
  }
}
ion-modal {
  &::part(content) {
    border-radius: 20px;
  }
}
ion-datetime {
  --background: var(--ion-color-light);
  --background-rgb: var(--ion-color-light-rgb);
  border-radius: 20px;
}
