.colour-description-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    flex-wrap: wrap;
    font-size: 12px;
    .inner-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        .inner-content{
            width: 20px;
            height: 5px;
        }
    }
}
