@import '../../../../scss/mixins.scss';
.production-budget-page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.production-budget-page-container {
  width: 100%;
  max-width: 1600px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.production-budget-container {
  width: 100%;

  @include for-desktop-up {
    gap: 50px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
  }

  ion-label {
    letter-spacing: 1px;
    color: gray;
    margin: 0;
  }

  .production-budget-datelock {
    width: 100%;
  }

  .production-row-container {
    @include for-desktop-up {
      width: 60%;
    }
    ion-row {
      ion-col {
        margin: 0;
        p {
          margin: 0;
        }
      }
    }
    .production-comment-section {
      border-bottom: 1px solid black;
      margin-bottom: 1.5rem;
    }
  }
}

ion-modal.lock-date-modal {
  --max-width: 400px;
  --height: 60%;
  --min-height: auto;
  --max-height: auto;
  --overflow: hidden;
  --border-radius: 30px;
  --border-width: 0;
  --border-style: none;
  --border-color: transparent;

  --box-shadow: none;
}
form.projectLockForm {
  height: 100%;
}

.date-locked {
  color: #edac53 !important;
}

.date-unlocked {
  color: green !important;
}
