@import '../../scss/mixins.scss';
$insetTopConst: calc(constant(safe-area-inset-top) + 26.5px);
$insetTopEnv: calc(env(safe-area-inset-top) + 26.5px);
$thirtyPX: 30px;
$fortyfivePX: 45px;
.accordionHeader {
    &::part(native) {
        padding: 0 !important;
    }
    button {
        &::part(native) {
            padding: 0 !important;
            width: 100% !important;
        }
    }
}
.menu-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.hamburger-menu-start {
    z-index: 120;
    margin-top: $insetTopConst;
    margin-top: $insetTopEnv;
    width: $thirtyPX;
    height: $thirtyPX;
    text-align: center;
    position: absolute;
    right: 20px;

    ion-icon {
        cursor: pointer;
    }
}

.menu-holder {
    position: relative;
}

.close-hamburger-menu {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-top: -$fortyfivePX;
    margin-bottom: -$fortyfivePX;
    margin-right: -20px;
    background: white;
}

.logo-holder {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 24px;
}
.hamburger-menu-open {
    z-index: 121;
    width: 60%;
    max-width: 400px;
    background-color: white;
    position: absolute;
    right: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border-radius: $fortyfivePX 0 0px $fortyfivePX;
    margin-top: $insetTopConst;
    margin-top: $insetTopEnv;
    padding: 24px 12px;

    ion-list {
        width: 100%;
        padding-right: 20px;
        padding-bottom: 24px;
        position: relative;
    }

    ion-item {
        &::part(native) {
            background: none;
            font-size: 14px;
        }
    }
    div {
        position: absolute;
        cursor: pointer;
        right: 20px;
        top: 15px;
    }

    ul {
        margin-top: 30%;
        padding-left: 16px;

        li {
            list-style: none;
            margin: 0;
            font-size: 1.6rem;
        }
    }
}

div.app-version-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .e2u-logo-black {
        filter: invert(100%);
        height: 20px;
    }
}
